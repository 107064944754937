import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import loading from 'redux/reducers/loading'
import alert from 'redux/reducers/alert'
import report from 'redux/reducers/report'
import masterData from 'redux/reducers/masterData'

const composeEnhancers =
  (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const enhancer = composeEnhancers(applyMiddleware(thunk))

export default createStore(
  combineReducers({
    loading,
    alert,
    report,
    masterData,
  }),
  enhancer
)
