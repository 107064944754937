import styled from 'styled-components'

import ReportLayout from '../../components/layouts/ReportLayout'

import imageBanner from '../../assets/images/report/banner-new-jouney.png'

const Div = styled.div`
  > .title {
    font-family: 'kelson_sansbold';
    font-size: 48px;
    line-height: 56px;
    text-transform: uppercase;
    color: var(--off-black);
    text-align: center;
  }

  > .banner {
    margin-top: 56px;
    width: 100%;
  }

  > .desc {
    margin-top: 32px;
  }

  .section-bottom {
    margin-top: 56px;

    .section-wrapper {
      display: flex;
      margin: 0 -24px;

      .left {
        flex: 1;
        margin: 0 24px;
        color: var(--off-black);
        text-transform: uppercase;

        .title {
          font-family: 'kelson_sansbold';
          font-size: 32px;
          line-height: 40px;
        }

        .sub-title {
          font-family: 'kelson_sans';
          margin-top: 8px;
          font-size: 24px;
          line-height: 32px;
        }
      }

      .right {
        flex: 1;
        margin: 0 24px;

        button {
          width: 100%;
          margin-top: 48px;
        }
      }
    }
  }
`

const ReportNewJourney = () => {
  return (
    <ReportLayout>
      <Div>
        <div className="title">
          YOUR HEALTH IS A JOURNEY. IT’S A JOURNEY TO BE HEALTHY. ITS NOT A DESTINATION.
        </div>
        <img className="banner" src={imageBanner} />
        <div className="section-bottom">
          <div className="section-wrapper">
            <div className="left">
              <div className="title">Choose Your Path:</div>
              <div className="sub-title">The journey to your destination lays within you.</div>
            </div>
            <div className="right">
              <div className="content">
                <p>
                  เปลี่ยนผลลัพธ์ให้เป็นจุดเริ่มต้น อย่างที่เกริ่นว่า “Healthy is a journey, It’not a
                  destination” ความสมบูรณ์ของร่างกายร่างกายส่วนหนึ่งมาจากพันธุกรรมก็จริง
                  แต่ไม่ใช่ทั้งหมด ผลลัพธ์จากการตรวจไม่อนาคตและไม่ใช่เป็นบทสรุปทุกคน
                </p>
                <p>
                  “มีความเสี่ยงสูงกว่าค่าเฉลี่ย” ไม่ได้หมายความว่าจะต้องป่วยเสมอไป แต่หมายถึง
                  “ควรสังเกตุตัวเอง เพื่อให้รู้จักตัวเองให้มากขึ้นอีกนิด” และลองใส่ใจเรื่องการกิน
                  นอน ใช้ชีวิต การผ่อนคลาย การออกกําลังกายให้เหมาะกับตนเอง
                  เพื่อให้ตัวเองให้ห่างไกลจากความเสี่ยงที่แฝงอยู่
                  แต่ถ้าหากมีความกังวลสามารถตรวจรักษา ดูแลตัวเองได้ตั้งแต่เนิ่นๆ “มีความเสี่ยงต่ํา”
                  ไม่ได้หมายความว่าจะไม่มีโอกาสเจ็บป่วย แต่หมายถึงมีพื้นฐานที่ดีอยู่แล้ว
                  แต่อย่าบุ่มบ่าม หรือปล่อยปละเลยในการใช้ชีวิต ไม่เช่นนั้นอาการต่างๆ ก็เกิดขึ้นมาได้
                </p>
                <p>
                  “มีความเสี่ยงปานกลางในระดับค่าเฉลี่ย” ก็หมายถึง เรามีพื้นฐานพอๆ กับคนอีกหลายๆ
                  แต่ปลายทางสุขภาพของแต่ละคนย่อมไม่เหมือนกัน อยู่ที่การใช้ชีวิต “ทิศทางชึวิต
                  เปลี่ยนได้จากการใช้ชีวิต” “การใช้ชีวิตที่ดี คือ
                  การใช้ชีวิตที่เหมาะกับร่างกายของคุณ”
                </p>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </ReportLayout>
  )
}

export default ReportNewJourney
