import styled from 'styled-components'

const StyledButton = styled.button`
  font-family: 'kelson_sansbold';
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px 24px;
  border: none;
  color: ${({ invert }) => (invert ? 'var(--off-black)' : 'var(--off-white)')};
  background: ${({ invert }) => (invert ? 'var(--off-white)' : 'var(--off-black)')};
  cursor: pointer;

  .prefix-body {
    margin-right: 12px;
  }

  .suffix-body {
    margin-left: 12px;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
`

const Button = ({ className, prefix, suffix, onClick, invert, disabled, children }) => {
  return (
    <StyledButton className={className} onClick={onClick} invert={invert} disabled={disabled}>
      {prefix && <span className="prefix-body">{prefix}</span>}
      {children}
      {suffix && <span className="suffix-body">{suffix}</span>}
    </StyledButton>
  )
}

export default Button
