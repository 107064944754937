import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from './Button'

const StyledButton = styled(Button)``

const NextButton = (props) => (
  <StyledButton {...props} prefix={<FontAwesomeIcon icon={['fas', 'arrow-right']} />} />
)

export default NextButton
