import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import ReportLayout from '../../components/layouts/ReportLayout'
import Button from '../../components/common/button/Button'

import imageBanner from '../../assets/images/report/banner-special-consult.png'
import imageQrcode from '../../assets/images/report/qrcode.png'

const StyledBanner = styled.div`
  color: var(--off-white);

  .intro {
    padding: 72px 160px;
    font-family: 'kelson_sansbold';
    text-transform: uppercase;
    font-size: 32px;
    line-height: 40px;
    height: 520px;
    background: url('${imageBanner}') no-repeat top center;
  }

  .sub-intro {
    padding: 72px 160px 88px;
    background: var(--off-black);

    .content-wrapper {
      text-align: center;

      .title {
        font-family: 'kelson_sansbold';
        text-transform: uppercase;
        font-size: 56px;
        line-height: 64px;
      }

      .desc {
        margin-top: 32px;
        margin-bottom: 16px;
        font-family: 'kelson_sans';
        text-transform: uppercase;
        font-size: 20px;
        line-height: 26px;
      }

      button {
        background: var(--off-white);
        color: var(--off-black);
      }
    }

    .sub-content-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 80px;
      color: var(--placeholder);

      .label {
        font-family: 'kelson_sansbold';
        text-transform: uppercase;
        font-size: 20px;
      }

      .content {
        margin-top: 6px;
        font-size: 20px;
      }

      .right {
        padding-right: 80px;
        background: url('${imageQrcode}') no-repeat bottom right;
      }
    }
  }
`

const Banner = () => {
  const history = useHistory()

  return (
    <StyledBanner>
      <div className="intro">
        We are with you.
        <br />
        Say hello to
        <br />
        your new healthy habit.
      </div>
      <div className="sub-intro">
        <div className="content-wrapper">
          <div className="title">Special for you</div>
          <div className="desc">Apply code:Freeconsult30</div>
          <Button>Free 30 mins doctor consulting</Button>
        </div>
        <div className="sub-content-wrapper">
          <div className="left">
            <div className="label">How to redeem code in 5 Step</div>
            <div className="content">
              1. Visit our website Fusiontech.co
              <br />
              2. Login with your email and password
              <br />
              3. Consult Scheduling at Booking Menu
              <br />
              4. Input Promo CODE
              <br />
              5. Waiting for confirmation Message
            </div>
          </div>
          <div className="right">
            <div className="label">Need Assistance?</div>
            <div className="content">
              Please Feel Free to Talk with <br />
              our customer service
              <br />
              Mon-Sat : 8.00-19.00
              <br />
              Via Line@ Fusion.tech
              <br />
              or scan QR Code
            </div>
          </div>
        </div>
      </div>
    </StyledBanner>
  )
}

const ReportSpeacialConsult = () => {
  return <ReportLayout header={<Banner />} />
}

export default ReportSpeacialConsult
