// import {
//   GET_MASTER_DATA_LOT_ID,
//   GET_MASTER_DATA_PROVINCE,
//   GET_MASTER_DATA_DISTRICT,
//   GET_MASTER_DATA_SUB_DISDTRICT,
// } from 'redux/actionTypes'

const initialState = {
  lotId: [],
  proviceData: [],
  districtData: [],
  subDistrictData: [],
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state
  }
}
